<template>
  <div style="display: flex;">
    <button @click="half" style="white-space:nowrap; width: 2em; border-radius: .4em 0 0 .4em;">
      /2
    </button>
    <div style="text-align: center; min-width: 2.5em; margin: 0 0; background-color: #777; font-size:.7em; padding: 0 .3em; display: flex; justify-content: center; align-items: center;">
      {{ formattedQuantFactor }}
    </div>
    <button @click="double" style="white-space:nowrap; width: 2em; border-radius: 0 .4em .4em 0;">
      *2
    </button>
  </div>
</template>

<script>
export default {
  name: "QuantFactor",
  props: {
  },
  data() {
    return {
      quantFactor: this.value
    }
  },
  computed: {
    formattedQuantFactor() {
      if (this.$store.state.quantFactor < 1) {
        return `1/${1 / this.$store.state.quantFactor}`
      }
      return `${this.$store.state.quantFactor}`
    }
  },
  methods: {
    half () {
      this.$store.state.quantFactor = this.$store.state.quantFactor / 2
    },
    double () {
      this.$store.state.quantFactor = this.$store.state.quantFactor * 2
    }
  },
}
</script>

<style>

</style>