<template>
  <div>
    <canvas
      ref="canvas"
      :width="$store.state.canvasSizeX"
      :height="$store.state.canvasSizeY"
      style="width: 100%; image-rendering: optimizespeed;
    ">
    </canvas>
  </div>
</template>

<script>
import helpers from '../helpers.js'

export default {
  name: 'FunPreview',
  props: {
    scene: {
      type: Object,
      default: () => null
    },
  },
  mounted () {
    this.draw();
  },
  methods: {
    draw() {
      if(this.scene) {
        helpers.draw(this.$refs.canvas, this.scene)
      }
      window.requestAnimationFrame(this.draw)
    }
  },
  // watch: {
  //   fun(newValue) {
  //     console.log('FunPreview', this.$refs.canvas, newValue)
  //     console.log(helpers.draw)
  //   }
  // },
}
</script>

<style>

</style>