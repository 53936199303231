<template>
  <div style="display:flex; width: 100%">
    <input 
      :class="{'error':$store.state.funError}" 
      style="border-radius: .4em 0 0 .4em"
      type="text" 
      v-model="funInputValue"
    >
    <button
      @click="onToggleFav"
      style="width: 2em; border-radius: 0 .4em .4em 0"
    >
      <template v-if="favAdded">
        <svg style="height: .8em;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-through-heart-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.854 15.854A.5.5 0 0 1 2 15.5V14H.5a.5.5 0 0 1-.354-.854l1.5-1.5A.5.5 0 0 1 2 11.5h1.793l3.103-3.104a.5.5 0 1 1 .708.708L4.5 12.207V14a.5.5 0 0 1-.146.354l-1.5 1.5ZM16 3.5a.5.5 0 0 1-.854.354L14 2.707l-1.006 1.006c.236.248.44.531.6.845.562 1.096.585 2.517-.213 4.092-.793 1.563-2.395 3.288-5.105 5.08L8 13.912l-.276-.182A23.825 23.825 0 0 1 5.8 12.323L8.31 9.81a1.5 1.5 0 0 0-2.122-2.122L3.657 10.22a8.827 8.827 0 0 1-1.039-1.57c-.798-1.576-.775-2.997-.213-4.093C3.426 2.565 6.18 1.809 8 3.233c1.25-.98 2.944-.928 4.212-.152L13.292 2 12.147.854A.5.5 0 0 1 12.5 0h3a.5.5 0 0 1 .5.5v3Z"/>
        </svg>        
      </template>
      <template v-else>
        <svg style="height: .8em;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-through-heart" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.854 15.854A.5.5 0 0 1 2 15.5V14H.5a.5.5 0 0 1-.354-.854l1.5-1.5A.5.5 0 0 1 2 11.5h1.793l.53-.53c-.771-.802-1.328-1.58-1.704-2.32-.798-1.575-.775-2.996-.213-4.092C3.426 2.565 6.18 1.809 8 3.233c1.25-.98 2.944-.928 4.212-.152L13.292 2 12.147.854A.5.5 0 0 1 12.5 0h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.854.354L14 2.707l-1.006 1.006c.236.248.44.531.6.845.562 1.096.585 2.517-.213 4.092-.793 1.563-2.395 3.288-5.105 5.08L8 13.912l-.276-.182a21.86 21.86 0 0 1-2.685-2.062l-.539.54V14a.5.5 0 0 1-.146.354l-1.5 1.5Zm2.893-4.894A20.419 20.419 0 0 0 8 12.71c2.456-1.666 3.827-3.207 4.489-4.512.679-1.34.607-2.42.215-3.185-.817-1.595-3.087-2.054-4.346-.761L8 4.62l-.358-.368c-1.259-1.293-3.53-.834-4.346.761-.392.766-.464 1.845.215 3.185.323.636.815 1.33 1.519 2.065l1.866-1.867a.5.5 0 1 1 .708.708L5.747 10.96Z"/>
        </svg>
      </template>
    </button>
    <LoadPreset @select="onSelectPreset" style="margin-left:.5em" />
  </div>
</template>

<script>
// import helpers from '../helpers.js'
import LoadPreset from '@/components/LoadPreset.vue'
import { mapState } from 'vuex'
export default {
  name: "FunInput",
  components: {
    LoadPreset
  },
  props: {
  },
  data() {
    return {
      funInputValue: this.$store.state.fun,
      favAdded: false,
    }
  },
  computed: {
    ...mapState([
      'fun',
    ])
  },
  watch: {
    funInputValue(newValue) {
      this.favAdded = false
      this.$store.commit('setFun', newValue)
    },
    fun(newValue) {
      this.funInputValue = newValue
    }
  },
  methods: {
    onSelectPreset(fun) {
      this.fun = fun
    },
    async onToggleFav() {
      if (!this.favAdded) {
        const funObject = await this.$store.dispatch('addFavItem', this.fun)
        this.$store.state.selectedFav = funObject.uuid
        this.favAdded = true
      } else {
        this.$store.commit('removeFavItem', this.$store.state.selectedFav)
        this.$store.state.selectedFav = null
        this.favAdded = false
      }
    }
  },
}
</script>

<style>
.error {
  color: #ff0 !important;
}

</style>