<template>
  <button @click="tap" style="white-space:nowrap">
    <template v-if="$store.state.bpm">
      {{$store.state.bpm}}
    </template>
    <template>
      bpm
    </template>
  </button>
</template>

<script>
export default {
  name: "BeatTap",
  props: {
  },
  data() {
    return {
      timestamps: [],
    }
  },
  computed: {
  },
  methods: {
    tap() {
      this.timestamps.push(new Date())
      const len = this.timestamps.length
      if (len >= 4) {
        const t1 = this.timestamps[len-1]
        const t2 = this.timestamps[len-2]
        const t3 = this.timestamps[len-3]
        const t4 = this.timestamps[len-4]
        const off12 = t1 - t2 
        const off23 = t2 - t3 
        const off34 = t3 - t4 
        const offMedian = (off12 + off23 + off34) / 3
        this.$store.state.bpm = Math.round( 60 / (offMedian / 1000) )
      } else if (len >= 2) {
        this.$store.state.bpm = Math.round( 60 / ((this.timestamps[len-1] - this.timestamps[len-2]) / 1000) )
      }
    }
  },
}
</script>

<style>

</style>