<template>
  <div class="fun-library" style="display: flex;">
    <button @click="toggleModal">Library</button>
    <div v-if="showModal" class="presets-modal modal">
      <div class="modal-backdrop" @click="toggleModal"></div>
      <div class="modal-position">
        <div class="modal-content">
          <div class="favs">
            <div 
              v-for="fav in $store.state.funFavs"
              class="fav-item"
              :key="`fav-${fav.uuid}`"
            >
              <img
                @click="setFun(fav)" 
                class="fav-item-img"
                :src="fav.preview" 
                style="image-rendering: crisp-edges;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../helpers.js'

export default {
  name: '',
  data() {
    return {
      selected: "",
      showModal: false
    }
  },
  computed: {
    presets () {
      return helpers.presets()
    }
  },
  methods: {
    setRandomPreset () {
      const listLength = this.presets.length
      const random = Math.floor(Math.random()*listLength)
      const randomPreset = this.presets[random]

      console.log(this.presets,listLength, random , randomPreset)
      this.$emit('select', randomPreset)
    },
    toggleModal() {
      this.showModal = !this.showModal
    },
    setFun (fav) {
      console.log(fav)
      this.$store.state.selectedFav = fav.uuid
      this.$store.state.fun = fav.value
    }
  },
}
</script>

<style lang="scss">
.fun-library {
  .modal-position {
    position: relative;
  }
  .modal-content {
    background-color: darkblue;
    padding: .5em;
    position: absolute;
    top: 30px;
    right: 0;
  }
  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }


  .favs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    max-height: 90vh;
    overflow: auto;
    .fav-item {
      width: 100%;
      line-height: 0;
      .fav-item-img {
        width: 100px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

</style>