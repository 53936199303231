<template>
  <div class="presets" style="display: flex;">
    <button @click="togglePresetsModal">Presets</button>
    <div v-if="showPresetsModal" class="presets-modal modal">
      <div class="modal-backdrop" @click="togglePresetsModal"></div>
      <div class="modal-position">
        <div class="modal-content">
          <button @click="setRandomPreset" style="white-space:nowrap">Random</button>
    
    
          <select 
            :value="selected" 
            @change="(onSelectChange($event))" 
            style="max-width:10em;"
          >
            <option 
              v-for="(preset, index) in presets" 
              :key="`preset-${index}`" 
              :value="preset"
            >
              {{ preset }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../helpers.js'

export default {
  name: '',
  data() {
    return {
      selected: "",
      showPresetsModal: false
    }
  },
  computed: {
    presets () {
      return helpers.presets()
    }
  },
  methods: {
    setRandomPreset () {
      const listLength = this.presets.length
      const random = Math.floor(Math.random()*listLength)
      const randomPreset = this.presets[random]

      console.log(this.presets,listLength, random , randomPreset)
      this.$store.state.fun = randomPreset
    },
    onSelectChange (evt) {
      const fun = evt.target.value
      console.log(fun)
      this.selected = fun
      this.$store.state.fun = fun
    },
    togglePresetsModal() {
      this.showPresetsModal = !this.showPresetsModal
    }
  },
}
</script>

<style lang="scss">
.presets {
  .modal-position {
    position: relative;
  }
  .modal-content {
    background-color: darkblue;
    padding: .5em;
    position: absolute;
    top: 30px;
    right: 0;
  }
  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

</style>