<template>
  <div style="display: flex;">
    <button @click="toggleModal" style="width:2em;">
      ?
    </button>
    <div
      v-if="showModal"
      class="modal help"
    >
  
      <div 
        @click="toggleModal"
        class="modal-backdrop" 
      ></div>
      <div class="modal-position">
        <div class="modal-content">
          <p>Welcome to fun.ck.si :)</p>
          <p>The function in the top bar gets computed for each pixel on a matrix in real time.</p>
          <p>The target output range is from 0 (black) to 1 (full brightness).</p>
          <div v-for="category, catIndex in vars" :key="`var-${catIndex}`">
            <h4>{{category.name}}</h4>
            <div v-for="(i, index) in category.items" :key="`operator-${index}`">
              <span class="operator">
                {{i[0]}}</span>
              <span>
                {{i[1]}}
              </span>
            </div>
          </div>

          <p>For addressing a single color channel, prefix <span class="var">r</span>, <span class="var">g</span> or <span class="var">b</span> to the variable.</p>
          <div v-for="category, catIndex in dictionary" :key="`cat-${catIndex}`">
      
            <h4>{{category.name}}</h4>
            <div v-for="(i, index) in category.items" :key="`operator-${index}`">
              <span class="operator">
                {{i[0]}}</span>
              <span>
                {{i[1]}}
              </span>
            </div>
          </div>

          <p>fun.ck.si is free and open source. If you are curious about the mechanics, you can <a href="https://gitlab.com/redplanet/fun-canvas/" target="_blank"> watch the source code</a>. Merge requests for better performance, bugfixes and presets are very welcome. </p>
          <p>Please discuss feature requests with the author before putting a lot of time into programming.</p>
          <p><b>Have a FUN time!</b></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '../helpers.js'

export default {
  props: {
    scene: {
      type: Object
    },
  },
  data() {
    return {
      showModal: false,
      now: Date.now(),
      beat: 0,
      volume: 0,
      realtimeVals: null,
    }
  },
  computed: {
    vars () {
      return [
          {
            name: 'variables',
            items: [
              ['x', 'pixel position on x-axis'],
              ['y', 'pixel position on y-axis'],
              ['f', 'fill (everything)'],
              ['b', `linear sawtooth beat (${this.beat})`],
              ['t', `time (${this.now})`],
              ['v', `volume (${this.volume})`],
            ]
          },
        ]
    },
    dictionary () {
      return [
          {
            name: 'arithmetic operators',
            items: [
              ['+', 'Addition / plus'],
              ['-', 'Subtraction / minus'],
              ['*', 'Multiplication / times'],
              ['**', 'Exponentiation / power'],
              ['/', 'Division'],
              ['%', 'Remainder'],
            ]
          },
          {
            name: 'logical operators',
            items: [
              ['>', 'greater than'],
              ['<', 'less than'],
              ['!', 'NOT'],
            ]
          },
          {
            name: 'bitwise operators',
            items: [
              ['~', 'Bitwise NOT (flip bits)'],
              ['&', 'Bitwise AND'],
              ['|', 'Bitwise OR'],
              ['^', 'Bitwise XOR'],
              ['<<', 'Bitwise left shift operator'],
              ['>>', 'Bitwise right shift operator'],
              ['>>>', 'Bitwise unsigned right shift operator'],
            ]
          },
          {
            name: 'functions',
            items: [
              ['sin(x)', 'sinus'],
              ['cos(x)', 'cosinus'],
              ['tan(x)', 'tangent'],
              ['log(x)', 'logarithm'],
              ['atan(x)', 'arctangent'],
              ['atan2(x)', 'arctangent 2'],
              ['sqrt(x)', 'radical'],
              ['exp(x)', 'e raised to the power of x'],
              ['abs(x)', 'convert to positive number'],
              ['round(x)', 'round to the nearest integer'],
              ['floor(x)', 'remove comma values'],
            ]
          }
        ]
    },
  },
  methods: {
    toggleModal() {
      console.log('this.showModal', this.showModal)
      this.showModal = !this.showModal

      if (this.showModal == true) {
        this.realtimeVals = setInterval( () => {
            this.now = Date.now()
            this.beat = this.getBeat()
            this.volume = this.getVolume()
        }, 100)
      }
      if (this.showModal == false) {
        clearInterval(this.realtimeVals);
      }

    },
    getBeat () {
      return helpers.getBeat(
          (new Date()/1000), 
          this.$store.state.bpm, 
          this.$store.state.quantFactor, 
          this.$store.state.beatOffset,
          this.$store.state.quantScale,
        )
    },
    getVolume () {
      return helpers.getVolume(
        this.scene.audioAnalyser,
        this.$store.state.audioFrequencyBinStart,
        this.$store.state.audioFrequencyBinEnd
      )
    }
  },
  created () {
  },
}
</script>

<style lang="scss">
.operator {
  display: inline-block;
  min-width: 1.5em; 
  border: 1px solid #999; 
  border-radius: 2px; 
  padding: 0 .2em;
  font-family:'Courier New', Courier, monospace; 
}
.var {
  display: inline-block;
  border: 1px solid #999; 
  border-radius: 2px; 
  font-family:'Courier New', Courier, monospace; 
  padding: 0 .2em;
}
.help {
  a {
    color: #fff;
  }
  .modal-position {
    position: relative;
  }
  .modal-content {
    text-align: left;
    background-color: darkblue;
    padding: .5em;
    position: fixed;
    right: 0;
    top: 35px;
    bottom: 0;
    width: 30em;
    overflow: auto;
  }
  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

</style>