// eslint-disable-next-line no-unused-vars
const pattern = (fun, bpm, t, b, v, x, y) => {
  // console.log(this)
  // eslint-disable-next-line no-unused-vars
  const sin = Math.sin, cos = Math.cos, tan = Math.tan, log = Math.log, atan = Math.atan, atan2 = Math.atan2, sqrt = Math.sqrt, exp = Math.exp, abs = Math.abs, floor = Math.floor, round = Math.round

  const res = (eval(fun))
  if (res == true) { return 255 }
  if (res == false) { return 0 }
  return res*255
}


function getBeat(t, bpm, quantFactor, beatOffset, quantScale) {
  if (quantScale) {
    return t % ((60/bpm/quantFactor) - beatOffset) * quantFactor * 2
  }
  return t % ((60/bpm/quantFactor) - beatOffset) * 2
}

function getVolume (analyser, audioFrequencyBinStart, audioFrequencyBinEnd) {
  if (analyser) {
    const frequencyData = new Uint8Array(analyser.frequencyBinCount)
      .slice(audioFrequencyBinStart,audioFrequencyBinEnd)
    analyser.getByteFrequencyData(frequencyData)
    // const frequencyBinCount = analyser.frequencyBinCount
    const volume = frequencyData.reduce((previousValue, currentValue) => (previousValue + currentValue), 0)/frequencyData.length/256
    // console.log(audioFrequencyBinStart, audioFrequencyBinEnd, frequencyData, volume)
    return volume
  } else {
    return 0;
  }
}

// async function getAudioAnalyser() {
//   // constructor() {
//     var audioContext;
//     var analyserNode;

//     try {
//         window.AudioContext = window.AudioContext || window.webkitAudioContext;
//         audioContext = new AudioContext();
//     } catch (e) {
//         alert('Web Audio API is not supported in this browser');
//     }    
//     navigator.mediaDevices.getUserMedia({
//       audio: true,
//       video: false
//     }).then(stream => {
//       const source = audioContext.createMediaStreamSource(stream);
//       analyserNode = audioContext.createAnalyser(source);
//       analyserNode.fftSize = 32;
//       audioContext.resume();
//       source.connect(analyserNode);
//       console.log('analyserNode',analyserNode)
//       return analyserNode
//     })
//   // }
// }

// render the scene on the canvas
function render(ctx,canvasWidth,canvasHeight, scene) {

  // Define all the pre-defined variables/constants
  // eslint-disable-next-line no-unused-vars
  let rx, ry, gx, gy, bx, by, rb, bb, gb, rf, gf, bf, rv, gv, bv
  const t = (new Date()/1000)
  // eslint-disable-next-line no-unused-vars
  const b = getBeat(t, scene.bpm,scene.quantFactor,scene.beatOffset, scene.quantScale)
  const matrix = new Array(canvasHeight).fill(0).map(() => new Array(canvasWidth).fill(0))
  const pixeldata = ctx.createImageData(1,1)
  const d = pixeldata.data
  // eslint-disable-next-line no-unused-vars
  const v = getVolume(scene.audioAnalyser, scene.audioFrequencyBinStart, scene.audioFrequencyBinEnd)

  // split the function into 3 RGB channels
  const black = 0
  const fill = 1
  const funR = scene.fun
    .replaceAll('rx', 'x')  .replaceAll('ry', 'y')
    .replaceAll('gx', black).replaceAll('gy', black)
    .replaceAll('bx', black).replaceAll('by', black)
    .replaceAll('rb', b)    .replaceAll('gb', black).replaceAll('bb', black)
    .replaceAll('rv', 'v')  .replaceAll('gv', black).replaceAll('bv', black)
    .replaceAll('rf', fill) .replaceAll('gf', black).replaceAll('bf', black)
    .replaceAll('f', fill)
    
  const funG = scene.fun
    .replaceAll('rx', black).replaceAll('ry', black)
    .replaceAll('gx', 'x')  .replaceAll('gy', 'y')
    .replaceAll('bx', black).replaceAll('by', black)
    .replaceAll('rb', black).replaceAll('gb', b)    .replaceAll('bb', black)
    .replaceAll('rv', black).replaceAll('gv', 'v')  .replaceAll('bv', black)
    .replaceAll('rf', black).replaceAll('gf', fill) .replaceAll('bf', black)
    .replaceAll('f', fill)
    
  const funB = scene.fun
    .replaceAll('rx', black).replaceAll('ry', black)
    .replaceAll('gx', black).replaceAll('gy', black)
    .replaceAll('bx', 'x')  .replaceAll('by', 'y')
    .replaceAll('rb', black).replaceAll('gb', black).replaceAll('bb', b)
    .replaceAll('rv', black).replaceAll('gv', black).replaceAll('bv', 'v')
    .replaceAll('rf', black).replaceAll('gf', black).replaceAll('bf', fill)
    .replaceAll('f', fill)
    
  // draw each pixel in a matrix
  matrix.forEach( (row, y) => {
    row.forEach( (pixel, x) => {
      // console.log(x, y)
      // const greyscale = pattern(fun, bpm, t, b, x, y)
      d[0] = pattern(funR, scene.bpm, t, b, v, x, y)
      d[1] = pattern(funG, scene.bpm, t, b, v, x, y)
      d[2] = pattern(funB, scene.bpm, t, b, v, x, y)
      d[3] = 255
      // console.log(pixel)
      ctx.putImageData( pixeldata, x, y )
      // console.log(i)
      // i = i+1
    })
  })
}


function draw(canvasEl, scene) {
  // console.log('start drawing')

 // Evaluate the user-supplied code, which must bind a value to Fun.
 
 if (canvasEl.getContext) {
  
    // Set up the canvas:
    const ctx = canvasEl.getContext('2d', { alpha: false })
    // Ctx.clearRect(0, 0, Width, Height)

    // Draw:
    // console.log('draw', ctx, canvasEl.width, canvasEl.height, fun, bpm)
    render(ctx, canvasEl.width, canvasEl.height, scene)
    // console.log('stop drawing')

  } else {
    // Do nothing.
  }
}
function funValidate(fun) {
  try {
    // eslint-disable-next-line no-unused-vars
    const sin = Math.sin, cos = Math.cos, tan = Math.tan, log = Math.log, atan = Math.atan, atan2 = Math.atan2, sqrt = Math.sqrt, exp = Math.exp, abs = Math.abs, floor = Math.floor, round = Math.round


    // eslint-disable-next-line no-unused-vars
    let x, y, b, t, v = 0, bpm = 120
    // eslint-disable-next-line no-unused-vars
    let rx, ry, gx, gy, bx, by, rb, gb, bb, f, rf, gf, bf, rv, gv, bv = 0
    this.error = false
    eval(fun)
    console.log(fun)
    return true 
  } catch (err) {
    console.log('bad functon', err)
    return false 
  }

}

// log(x/y)+sin(x)*y/b+x
// y/y % (((x-120)^y/190)/((y-150)^x/20))


const presets = function() {
  return [
    'x/y',
    'abs(b-0.5)',
    'abs(rb-0.5*rf)',
    'abs(rb-0.5*rf)*x*y/200 + gx/200 + by/100',
    'sin(x%y&y/10^x/y)',
    '(x*100000) << (y/3)', // left shift operator
    'y >> (x^y)+30*b', // right shift operator
    'y >> (y+b*-30^x+b*30)+30*b',
    '(t % (1800/bpm)) * y/(x^2) %  (( (x+1000)^y/b*100) / ((y-10)*x/0.1^y*(t % (240/bpm))*300) )',
    'tan(x*t/10003-y*t/11212*x)/y',
    '(y + 19*x) % (121 + y) % b*20 *-1',
    '(y + 19*x) % (122 + y) % b*21+b*90 *-1',
    '(y + 19*x) % (1080 + y) % b*21+b*90 *-1',
    '((x-150)/(y)) * (b*2-2) % (y*x*b/50000)',
    '((x-150)/(y)) * (b*2-1) % (y/x*b)',
    '((x-150)/(y)) * (b*2-1) % (y/x*b-1.05)',
    '( (b*6-b+3.03) % (y/x*b-b/34) ) -.3',
    '( (120*10-b+19.03*b) % (x/y/1 + 3) )',
    'y/y % (((gx-100)^ry/100*b*8)/((y-250)^rx))',
    '(rx*gx*bx * 1/200)  *  (ry*gy*by*rb*(bb**1.2) * 1/100)',
    '((rx*gx*bx+100) * 1/200)  *  ((ry*gy*by+200)*rb*(bb**1.2) * 1/100) % 2',
    '9*y/y*((t-1679005555)/1) % ((gx-100)/((ry^y-250)^rx))',
    '9*y/y*((t-1679005555)/1) % ((gx-100)/((ry^y-250*(b/300))^rx))',
    '(16*rx/x*t/8 % (y % x^rx*gy))',
    '(sin(b%x)/sin(t%log(gy))) % gy-x/2',
    '(tan(b%x)/tan(t%log(gx))) % gy-x/b',
    'atan((x & y)/10)',
    'cos(x/y*t)',
    'cos(rx/by*t/10)',
    'y * sin(bx*99) + (x*(t-1679009870)/500)*tan(ry*99)',
    'y * sin(bx*99) + (x*b/100)*tan(ry*99)',
    'log(tan(x)-sin(y*b))',
    'sin(log((ry**20)*900-10000)-sin(rx*rb/10)-atan(b)) % (2*x)/(y-50)*4',
    'sin(log((ry**20)*900-10000)-sin(rx*rb/10)-atan(b/9)) % (2*x)/(y-50)*4',
    'sin(log(((ry**12*((t)))-10))-sin(rx*(t)/10)-tan((t)/3)) * -1',
    'sin(log(((ry**12*((t)))-10))-sin(rx*(b)/10)-tan((sin(log(((ry**12*((sin(log(((ry**12*(b))-10))-sin(ry*(b)/1)-tan(b/3)) * -1)))+3))-sin(rx*(b)/10)-tan((b)/3)) * -1)/3)) * -1',
    'cos(rx^2)+(by^2)*b', // meha
    'y/y % (((gx-0.100)^x/10*b)/((gx+y-0.250)^rx))*x',
    'x/y*b*12-x+10*sin(0.2*x)+y',
    'x/y*b*12-x+10*sin(0.2*x)+y%10*b',
    '(b-2.1)*rx%ry*b*12-x+10*sin(0.2*rx)+2*gx*gy%16*b',
    'b % ((rx-100)/((by^ry-250*(b/20))^gx))',
    'b % ((rx-100)/((by^ry-250*(b/20))^gx+y))',
    'b % (b*40/(1^gx^y))',
    'b % (b*40/(2*b^x^ry*b/x*14))',
    '(b % (b*40/(2*b^x^ry*y/x*14)))*gy/gx',
    '(b % (b*40/(2*b^x^ry*y/x*14)))*gy/gx/2&(y*x)',
    '(b % (b*5/(2+b^x/1/b^ry*y/x*14)))*gy/gx/2&(y+x)',
    '(b % (b**5/(2+b^x/1/b^ry*y/x*14)))*gy/gx/2&(y+x)',
    'by/rx/2&(x*-1)',
    '(bx/ry*b/2&(y*-1)*b )-1',
    '200*b*( (bx/ry&(y*-1*b/2)*b/b)/10-b)',
    '((80/x)/(y*0.5/b)) * sqrt(b+y-b&x) % (.5)',
    '(((b*10/x)/(y*0.5/b)) * sqrt(b+y-b&x) % (.3)*b) * ( x/y*b*12-x+10*sin(0.2*x)+y)*-1',
    '(((b*10/rx)/(y*0.5/b)) * sqrt(gy-b&x/1111*y-b*2) % (.3)*b) * ( x/y*b*1-x+110*b*sin(0.1*gx*bx*1.5)+by*3.9)*-1',
    '(gx&gy)',
    '((((gx*1500&ry*b^gx))/x+sin(x/6))*-1)*tan(bx%y)',
    '(((((gx*1500&ry*b^gx))/x+sin(x/6))*-1)*tan(bb*80%bx%by%b+1))**-24*(b+b*5)',
    '(((((gx*1&gy*b*1*(b/2)^3*gx))/x+sin(x/27)))*tan(bx%by%b+1))**-21*(b/2)',
    '((((((gx*1&gy*b*1*(b/2)^3*gx))/x+sin(y/7-b)))*tan(bx%by%b+1))*-21*(b/2)) % 9',
    'y&(x%((((((x*1&y*b*1*(b)^8*b*x))/x+sin(y/(b*-1)+2)))*atan(y%y%b+2))) - 6)',
    '9*(y/19)&(y%(tan(((((log(1*x*y/b)*rx))/x+atan(gy/(b*-1)+2)))*tan(y%x))) )',
    '3**(y/19)%y&(x%((((((y*b^1*b*rx))/x+sin(gy/(b*-1)+2)))*atan(y%b+9))) )',
    '3*(y/19)-b*7%y&(x%((((((22*y*b^1*b*rx))/x+sin(gy/(b*-1)+2)))*atan(y%b+9))) )',
    '3*(y/19)-b*7%y&(y%(tan(((((20*log(1*x*y)*b^1*b*rx))/x+sin(gy/(b*-1)+2)))*atan(x%b+20))) )',
    '3*(y/19)&(y%(tan(((((log(1*x*y/b)*rx))/x+atan(gy/(b*-1)+2)))*tan(y%x%y%2%x))) )',
    '(9*(gy/3)&(ry%(tan(((((log(x)))/log(y/b/50)))*b/10)) ))*cos(x/y*x*(1))',
    '((rx*(gb**-1) + 1*x) * (21 + sin(y)) % 42)-19',
    '((((rx*(gb**-1) + 1*x) * (21 + sin(y)) % 42)-19)**-1)-5', //wip
    '((((rx*(gb**-1) + 2*x) * (10/b*4 + tan(x/y/b*-1)) % 1142)-1)**-1) % x*y',
    'by/9&(bx%((((((bx*1&by*bb*1*(bb)^2*bb*bx))/bx+sin(by/(bb*-1)+2)))*tan(by%by%bb+2))) )/9',
    'by/9&(bx%((((((rx*1&by*bb*1*(bb)^2*bb*bx))/bx+sin(by/(bb*-1)+2)))*tan(by%by%bb+2))) )/9',
    'b*(x<90 && x>20 && 5<y && y <90*b && x%5 ? 1 : 0.2)', 
    '(x>y || 100-y>x) && ((rx>ry+90 || rx+255 < ry+260)) ^ (bx%y*b%y)',
    'atan((x+10*b-y/2 & y+100*b-x/2)/50)*-1+1',
    'tan((rx+10/4 & ry+100-rx/4+rb*15.2)/150)-y/300',
    '((((x-100)**2+(y-50)**2))-1000)', //circle
    '((((x-100)**2+(y-50)**2))&1000*b)',
    '((((x-100)**2+(y-50)**2))&10000*(b)) * -1+1',
    '((((x-100)**2+(y-50)**2))&1000*(b+x/200)) * -1+1',
    '((((x-100)**2+(y-50)**2)&100000+(b*30))) * -1+1',
    '((((x-100)**2+(y-50)**2)-2000) - sin(x*y/20)*100*b)/200 * -1+1',
    '((((x-100)**2+(y-50)**2)-1000 * sin(x*y/1000*b*12))) * -1+1',
    '((((x-100)**2+(y-50)**2)-1000 * sin(x*y/1000*b*12))) % tan(((x-100)**2+(y-50)**2)/3000^y*((1*4-4/((x/10)))/10+1000000) * -1+1)',
    '(sin(x*y/1000*b) - (((x-100)**2+(y-50)**2))/1000)',
    '((y+100)&(x-100)*b/(y-50)*9) ** (((((x-100)**2+(y-50)**2))-2000))/100 * -1+1',
    'sin(b* (((((x-100)**2+(y-50)**2))-1000))/1000 * -1+1)',
    'tan((((((x-100)**2+(y-50)**2))))/3000^y*((b*4-4/((x/10)))/10+1000000) * -1+1)',
    'sin(((x^y)*(x^y))/100+b)',
    'sin(((x^y)-(x^y)/b/190))',
    'sin(((x^y)&(x^y)/b/140))',
    'sin(((x^y)&(1.3*x^y)/b/140)) && (((((x-100)**2+(y-50)**2))-2000))/100 * -1+1',
    'sin(x * sin(b) ) + sin((x + y) * cos(b) ) + sin(y * sin(b) ) + sin((x - y) * cos(b) )',
    '((((rx^by)&(33*x&y)/b/10))*(sin(b))*(x))', 
    '((y - 30 >> x/1.5 - 100/b << b * y+50 & 512))',
    '( y%20 - x%20 + b*10 - 20 )',
    '( x % 200 & y*2 % 10000001 % rb )-.5*rf',
    '( (y-50) %60 / (x-100) - 5*b & 2.5 )',
    '( (y+50) - 1000 % (x-100*b*8) )',
    '( y%20 - x%20 + b*10 - 20 ) & tan(x*b/100)',
    '( y%20 - x%20** + b*10 - 10 )',
    '((((((x-100)**2+(y-50)**2))-1000))/200 & y*t^(bb*0.2-gb**3) & atan(x) *sin(b+tan(t)))',
    '( (x+10*b) %50 / (y-50) - 1*b & 1 )',
    'sin(tan(log(x)**3<<y+b*100))',
    '((y+b*40)%20)/20',
    'sin((y-t*100&(x)^8)/10 - (bx%4*1))+sin(x/20)+sin(ry/10)',
    'sin((y-t*100&(x)*8)/10 - (bx%4*1))+sin(ry/10)+(sin(y*x/5))/10',
    'sin((y-t*420|x|68*b)/10 - (bx|4*1))+sin(x/20)+sin(ry/50)',
    'sin((y-t*8*b)/10 - (bx|1))+sin(x/22)+sin(ry/10)',
    'sin((y-t/10000000*b)/10 - (bx%6))+sin(x/40)+sin(ry/10)',
    'sin((y+100*b)/22 - (bx%9)) + sin(x%100/8) + sin(rx+ry/4)',
    '(sqrt(sqrt(rb/1+bb/4))-1  -  cos(bx%2) + tan(y*97%x*5%110/y/b/7) +  (10-y) /10/b/10**3*gf/10)/1',
    'sin(x*y&x/y)',
    '((bx/150)*(y-10*abs(b-.5))&y/x*70*abs(b-.5))/10',
    '(((bx/150)*(y-10*abs(b-.5))&y/x*70*abs(b-.5))/20 ) + (((rx/177)*(y-12*abs(b-.5))&y/x*500*abs(b-.5))/20 )/5',
    '(sin(x*(y)/900*b) + (((x^b*100^79)**2+(y-350)*100*b))%70/b)*0.01*gb',
  ]
}

export default {
  draw,
  funValidate,
  getBeat,
  getVolume,
  presets,
}