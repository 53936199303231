<template>
  <div class="fun-edior">
    <div class="fun-controls" style="display: flex">
      <FunInput />
      <FunLibrary style="margin-left: .5em;" />
      <BeatTap style="margin-left: .5em; width:5em" />
      <!-- <BeatSync v-model="beatOffset" :bpm="bpm" style="margin-left: .5em; width:5em" /> -->
      <QuantFactor v-model="quantFactor" style="margin-left: .5em;" />
      <FunSettings :scene="scene" style="margin-left: .5em;" />
      <FunHelp style="margin-left: .25em;" :scene="scene" />
      <ToggleFullscreen style="margin-left: .5em; margin-right: .5em;" />
    </div>
    <FunPreview v-if="ready" class="fun-preview" :scene="scene" />
  </div>
</template>

<script>
// import helpers from '../helpers.js'

import BeatTap from '@/components/BeatTap.vue'
// import BeatSync from '@/components/BeatSync.vue'
import QuantFactor from '@/components/QuantFactor.vue'
import FunInput from '@/components/FunInput.vue'
import FunPreview from '@/components/FunPreview.vue'
import FunSettings from '@/components/FunSettings.vue'
import FunHelp from '@/components/FunHelp.vue'
import FunLibrary from '@/components/FunLibrary.vue'
import ToggleFullscreen from '@/components/ToggleFullscreen.vue'

export default {
  name: 'FunEditor',
  components: {
    BeatTap,
    // BeatSync,
    QuantFactor,
    FunInput,
    FunLibrary,
    FunPreview,
    FunSettings,
    FunHelp,
    ToggleFullscreen,
  },
  props: {
  },
  data() {
    return {
      beatOffset: 0,
      quantFactor: 1,
      // audioSmoothingTimeConstant: this.$store.state.audioSmoothingTimeConstant,
      audioAnalyser: null,
      ready: false
    }
  },
  computed: {
    scene () {
      return {
        fun: this.$store.state.fun,
        bpm: this.$store.state.bpm,
        beatOffset: this.beatOffset,
        quantFactor: this.$store.state.quantFactor,
        quantScale: this.$store.state.quantScale,
        audioAnalyser: this.audioAnalyser,
        audioFrequencyBinStart: this.$store.state.audioFrequencyBinStart,
        audioFrequencyBinEnd: this.$store.state.audioFrequencyBinEnd,
        // audioSmoothingTimeConstant: this.$store.state.audioSmoothingTimeConstant,
      }
    },
    audioSmoothingTimeConstant () {
      return this.$store.state.audioSmoothingTimeConstant
    },
    audioFftSize () {
      return this.$store.state.audioFftSize
    }
  },
  methods: {
  },
  watch: {
    audioSmoothingTimeConstant(newValue) {
      this.audioAnalyser.smoothingTimeConstant = newValue
    },
    audioFftSize(newValue) {
      this.audioAnalyser.fftSize = newValue
    }
  },
  async mounted () {
    var audioContext
    var analyser

    try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContext = new AudioContext()
    } catch (e) {
        alert('Web Audio API is not supported in this browser')
    }    
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    }).then(stream => {
      const source = audioContext.createMediaStreamSource(stream)
      analyser = audioContext.createAnalyser(source)
      analyser.fftSize = this.$store.state.audioFftSize;
      analyser.smoothingTimeConstant = this.$store.state.audioSmoothingTimeConstant;
      audioContext.resume();
      source.connect(analyser);
      this.audioAnalyser = analyser
    })

    this.ready = true
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fun-edior {
  display: flex;
  flex-direction: column;
}
.fun-controls {
  margin-top:.3em;
  margin-bottom: .5em;
}

.fun-preview {
  width: 100%;
  // width:400px
}
</style>
