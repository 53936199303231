import Vue from 'vue'
import Vuex from 'vuex'
import createMutationsSharer from "vuex-shared-mutations";
import createPersistedState from "vuex-persistedstate";
import { v4 as uuidv4 } from 'uuid';
import helpers from '../helpers.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fun: "sin(x*y)",
    funError: false,
    canvasSizeX: 200,
    canvasSizeY: 100,
    bpm: 120,
    beatOffset: 0,
    quantFactor: 0.25,
    quantScale: true,
    audioSmoothingTimeConstant: 1,
    audioFftSize: 32,
    audioFrequencyBinStart: 0,
    audioFrequencyBinEnd: 5,
    funHistory: [],
    funFavs: [],
    selectedFav: null,
  },
  mutations: {
    setFun (state, fun) {
      const valid = helpers.funValidate(fun)
      console.log('valid', valid)
      state.selectedFav = null
      if (!valid) {
        state.funError = true
      } else {
        this.commit('addHistoryItem', fun)
        state.funError = false
        state.fun = fun
      }
    },
    addFavItem (state, funObject) {
      state.funFavs.push(funObject)
    },
    removeFavItem (state, funUuid) {
      console.log(funUuid, state.funFavs)
      // const funObj = state.funFavs.find(fav => fav.funUuid == funUuid)
      if( state.selectedFav == funUuid) {
        state.selectedFav = null
      }
      state.funFavs = state.funFavs.filter(favObject => favObject.uuid != funUuid)
    },
    addHistoryItem (state, fun) {
      // mutate state
      state.funHistory.push(
        {
          uuid: uuidv4(),
          timestamp: Date.now(),
          value: fun,
          bpm: state.bpm,
          quantFactor: state.quantFactor,
          quantScale: state.quantScale,
          canvasSizeX: state.canvasSizeX,
          canvasSizeY: state.canvasSizeY,
        }
      )
    },
  },
  actions: {
    addFavItem ({ state, commit }, fun) {
      let canvasEl = document.createElement('canvas');
      canvasEl.width  = state.canvasSizeX
      canvasEl.height = state.canvasSizeY
      helpers.draw(canvasEl, {
        fun: fun,
        bpm: state.bpm,
        beatOffset: 0,
        quantFactor: state.quantFactor,
        quantScale: state.quantScale,
      })
      const preview = canvasEl.toDataURL()

      const funObject = {
        name: fun,
        uuid: uuidv4(),
        timestamp: Date.now(),
        value: fun,
        preview: preview,
        bpm: state.bpm,
        quantFactor: state.quantFactor,
        quantScale: state.quantScale,
        canvasSizeX: state.canvasSizeX,
        canvasSizeY: state.canvasSizeY,
      }
      commit('addFavItem', funObject)
      return funObject
    },

  },
  modules: {
  },
  plugins: [
    createMutationsSharer({ predicate: ["mutation1", "mutation2"] }),
    createPersistedState()
  ]
})
