<template>
  <div class="home">
    <FunEditor />
  </div>
</template>

<script>
// @ is an alias to /src
import FunEditor from '@/components/FunEditor.vue'

export default {
  name: 'Home',
  components: {
    FunEditor
  }
}
</script>
