<template>
  <div style="display: flex;">
    <button @click="toggleModal" style="width:2em; text-align: center;">
      <svg style="margin: .15em -.3em 0;height:1em;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
        <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
      </svg>
    </button>
    <div
      v-if="showModal"
      class="modal settings"
    >
  
      <div 
        @click="toggleModal"
        class="modal-backdrop" 
      ></div>
      <div class="modal-position">
        <div class="modal-content">
          <div>Matrix dimensions</div>
          <div style="display:flex;">
            <input type="text" v-model="$store.state.canvasSizeX" style="text-align: center; width: 5em; margin: 0 .25em 0 0;">
            <div>×</div>
            <input type="text" v-model="$store.state.canvasSizeY" style="text-align: center; width: 5em; margin: 0 .25em;">
             = {{ $store.state.canvasSizeX * $store.state.canvasSizeY  }}px
          </div>

          <div style="margin-top: 1em;">Beats per minute</div>
          <div style="display:flex;">
            <input type="text" v-model="$store.state.bpm" style="text-align: center; width: 5em; margin: 0 .25em;">
          </div>

          <div style="margin-top: 1em;">Quantization factor</div>
          <div style="display:flex;">
            <input type="text" v-model="$store.state.quantFactor" style="text-align: center; width: 5em; margin: 0 .25em;">
            <div>= 1/{{ 1/$store.state.quantFactor }}</div>
          </div>

          <div style="margin-top: 1em; display: flex; align-items: flex-start;">
            <input id="quantScale_cb" type="checkbox" v-model="$store.state.quantScale" />
            <div>
              <label for="quantScale_cb">
                Rescale beat value on quantization
              </label>
             <small>
               <div v-if="$store.state.quantScale">Beat range: 0...1</div>
               <div v-if="!$store.state.quantScale">Beat range: 0...{{ 1/$store.state.quantFactor }}</div>
             </small>
            </div>
          </div>
          <div style="display:flex; margin-top: 1em;">
            <div style="margin-right: 1em;">Audio</div>
  
            <div style="display:flex;">
              <div 
                v-for="(v,i) in audioFrequencyBins" 
                :key="i" 
                :style="`display:flex; align-items: flex-end; height:30px; background: ${(!!v.included ? 'rgba(255,255,255,.2)' : 'none')}; border-bottom: 1px solid #fff; padding-bottom: 1px;`"
              >
                <div
                  :style="`height:${v.val/256*30}px; min-width: 2px; background: #fff; margin-right: 1px;`"
                >

                </div>
              </div>
            </div>
          </div>
          <div style="display:flex; flex-direction:column;">
          <div style="display:flex;">
            <div>smoothing</div>
            <input type="range" name="smoothing" min="0" max="0.99" v-model="$store.state.audioSmoothingTimeConstant" step="0.05" />
          </div>
          <div style="display:flex;">
            <div>FFT resolution</div>
            <select v-model="$store.state.audioFftSize">
              <option value="32">32</option>
              <option value="64">64</option>
              <option value="128">128</option>
              <option value="256">256</option>
              <option value="512">512</option>
            </select>
          </div>
          <div style="display:flex;">
            <div>range {{audioFrequencyBinStart}} to {{audioFrequencyBinEnd}}</div>
            <input type="number" min="0" :max="audioFrequencyBinEnd" v-model="$store.state.audioFrequencyBinStart" style="text-align: center; width: 5em; margin: 0 .25em;">
            to
            <input type="number" :min="audioFrequencyBinStart" :max="$store.state.audioFftSize/2" v-model="$store.state.audioFrequencyBinEnd" style="text-align: center; width: 5em; margin: 0 .25em;">
            <!-- <input type="range" min="0" :max="$store.state.audioFrequencyBinEnd" v-model="$store.state.audioFrequencyBinStart" step="1" />
            <input type="range" :min="$store.state.audioFrequencyBinStart" :max="$store.state.audioFftSize/2" v-model="$store.state.audioFrequencyBinEnd" step="1" /> -->
          </div>
          <!-- <input type="text" v-model="" style="text-align: center; width: 5em; margin: 0 .25em;"> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scene: {
      type: Object
    },
  },
  data() {
    return {
      showModal: false,
      audioFrequencyBins: []
    }
  },
  methods: {
    toggleModal() {
      console.log('this.showModal', this.showModal)
      this.showModal = !this.showModal
    },
    updateAudioFrequencyBins() {
      const analyser = this.scene.audioAnalyser
      if (analyser) {
        const frequencyData = new Uint8Array(analyser.frequencyBinCount)
        const filterStart = this.audioFrequencyBinStart
        const filterEnd = this.audioFrequencyBinEnd
        analyser.getByteFrequencyData(frequencyData)
        let audioFrequencyBins = frequencyData.reduce(function(result, item, index) {
          result[index] = {
            val:item,
            included: !!(index >= filterStart && index < filterEnd)
          }
          return result
        }, {})
        console.log(audioFrequencyBins)
        this.audioFrequencyBins = audioFrequencyBins
      }
      requestAnimationFrame(this.updateAudioFrequencyBins)
    }
  },
  computed: {
    audioFrequencyBinStart () {
      return this.$store.state.audioFrequencyBinStart
    },
    audioFrequencyBinEnd () {
      return this.$store.state.audioFrequencyBinEnd
    }
  },
  mounted () {
    this.updateAudioFrequencyBins();
  },
}
</script>

<style lang="scss">
.operator {
  display: inline-block;
  min-width: 1.5em; 
  border: 1px solid #999; 
  border-radius: 2px; 
  padding: 0 .2em;
  font-family:'Courier New', Courier, monospace; 
}
.var {
  display: inline-block;
  border: 1px solid #999; 
  border-radius: 2px; 
  font-family:'Courier New', Courier, monospace; 
  padding: 0 .2em;
}
.settings {
  a {
    color: #fff;
  }
  .modal-position {
    position: relative;
  }
  .modal-content {
    text-align: left;
    background-color: darkblue;
    padding: .5em;
    position: absolute;
    right: 0;
    top: 30px;
    width: 20em;
    overflow: auto;
    input {
      background-color: rgba(255,255,255,.2)
    }
  }
  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

</style>