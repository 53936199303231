<template>
  <button 
    v-if="!isFullscreen"
    @click="toggleFullscreen"
    style="white-space:nowrap; max-width: 6em; flex-grow: 0; flex-shrink: 1;"
  >
      fullscreen!

  </button>
</template>

<script>
export default {
  name: "ToggleFullscreen",
  props: {
  },
  data() {
    return {
      isFullscreen: false,
    }
  },
  computed: {
  },
  methods: {
    toggleFullscreen() {
      let elem = document.querySelector("body");

      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          );
        });
      } else {
        document.exitFullscreen();
        console.log(!!document.fullscreenElement)
      }
      this.$emit('change', true)
    },
    onToggleFullscreen(evt) {
      console.log(evt)
      if (document.fullscreenElement) {
        this.isFullscreen = true
      } else {
        this.isFullscreen = false
      }
    }
  },
  mounted () {
    document.querySelector("body").addEventListener('fullscreenchange', this.onToggleFullscreen)
  },
}
</script>

<style>

</style>